import React from "react"
//import { StaticImage } from "gatsby-plugin-image"
// import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import CartSvg from "../images/CartSvg"
import hohohoJson from "../../data/hohoholist.json"

const ItemsCard = () => {
  return (
    <div className="relative m-3 flex flex-wrap mx-auto justify-center max-w-screen-lg">
      {hohohoJson.map((item, index) => {
        return (
          <>
            {item.available && (
              <div
                key={index}
                className="relative max-w-sm w-[280px] bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer"
              >
                <a
                  href={item.productLink}
                  target={item.productLink ? "_blank" : ""}
                >
                  <div className="overflow-x-hidden rounded-2xl relative">
                    <img
                      alt={item.productName}
                      src={item.imageSrc}
                      className="h-40 rounded-2xl w-full object-cover"
                      placeholder="blurred"
                      layout="fullWidth"
                    />
                  </div>
                  <div className="mt-4 pl-2 mb-2 flex justify-between ">
                    <div>
                      <p className="text-lg font-semibold text-gray-900 mb-0">
                        {item.productName}
                      </p>
                      <p className="text-md text-gray-800 mt-0">
                        {item.productPrice}
                      </p>
                    </div>
                    {item.productLink !== "" ? (
                      <div className="flex flex-col-reverse mb-1 mr-4 group cursor-pointer">
                        <CartSvg />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </a>
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

export default ItemsCard
