import React from "react"

import { Grid, Typography, makeStyles } from "@material-ui/core"

import Layout from "../components/layout"
import Seo from "../components/seo"

import TeoAvatar from "../components/Images/TeoAvatar"

import ItemsCard from "../components/ItemsCard"

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: 5,
  },
}))

const IndexPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <Seo title="Gift Ideas for Teo" />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <TeoAvatar />

        <Grid item xs={12} md={12} align="center">
          <Typography component="h1" variant="h4">
            Gift Ideas for Teo
          </Typography>
          <Typography component="p" variant="body1">
            No expectations to purchase any items or link from this list, we only want to help with ideas and links to sites that will ship to us in NZ. If you find
            something on another site and want us to check shipping or customs fees just ask. Let us know if you want to tick something off our
            list to avoid any double-ups, and thank you, thank you, thank you! Lots of love from Teo! xo
          </Typography>

          <Typography
            component="p"
            variant="body2"
            style={{ fontStyle: "italic" }}
          >
            Last updated: 26 November 2022
          </Typography>
        </Grid>
        <ItemsCard />
        <Grid item xs={12} md={12} align="center"></Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage
